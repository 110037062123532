import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Note from "../../../../../components/note"
import Input from "../../../../../components/input"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "tagebuch",
}

const defaultInputs = {
  "q-01": {
    default: "Hölderlin reitet auf das Schloss zu.",
    placeholder: "z.B. Hölderlin reitet auf das Schloss zu.",
  },
  "q-02": {
    default: "Er hört Musik. Es gibt ein großes Fest im Schloss.",
    placeholder: "z.B. Er hört Musik. Es gibt ein großes Fest im Schloss.",
  },
  "q-03": {
    default: "So trifft Hölderlin auf Blum",
    placeholder: "z.B. So trifft Hölderlin auf Blum",
  },
  "q-04": {
    default: "Warum war Blum nicht am Treffpunkt?",
    placeholder: "z.B. Warum war Blum nicht am Treffpunkt?",
  },
  "q-05": {
    default: "Ein spannendes Ende",
    placeholder: undefined,
  },
}

const Page = () => {
  const { getFormData, setAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()

        const data = getFormData({ event })
        const answer = {
          "q-01": data["q-01"] || defaultInputs["q-01"].default,
          "q-02": data["q-02"] || defaultInputs["q-02"].default,
          "q-03": data["q-03"] || defaultInputs["q-03"].default,
          "q-04": data["q-04"] || defaultInputs["q-04"].default,
          "q-05": data["q-05"] || defaultInputs["q-05"].default,
        }

        setAnswer({
          ...taskProps,
          taskId: "tagebuch-01",
          answer,
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/tagebuch/aufgabe-2"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
      progress={progress}
    >
      <Seo title="Das Tagebuch weiterschreiben" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Das Tagebuch weiterschreiben
          </Heading>
        </Stack>
        <Note variant="task">
          Überlege dir, wie Hölderlins Tag weiter verlaufen sein könnte. Mache
          dir dazu einen Plan und notiere die Stationen seiner Reise in den
          Textfeldern. Das Wiedersehen mit Blum sollte der Höhepunkt sein.
        </Note>
        <Stack>
          <Input
            id="q-01"
            name="q-01"
            label="Was passiert als nächstes?"
            placeholder={defaultInputs["q-01"].placeholder}
          />
          <Input
            id="q-02"
            name="q-02"
            label="So geht es weiter"
            placeholder={defaultInputs["q-02"].placeholder}
          />
          <Input
            id="q-03"
            name="q-03"
            label="Höhepunkt"
            placeholder={defaultInputs["q-03"].placeholder}
          />
          <Input
            id="q-04"
            name="q-04"
            label="Auflösung"
            placeholder={defaultInputs["q-04"].placeholder}
          />
          <Input
            id="q-05"
            name="q-05"
            label="Das Ende"
            placeholder="z.B. Ende gut, alles gut?"
          />
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
